<template>
  <b-card no-body class="p-1" ref="userAccounts">
    <b-modal v-model="modalNativeCoin" ok-only>
      <div v-if="modalNativeCoinInfo">
        <div v-for="(value, key) in modalNativeCoinInfo" :key="key" class="native-coin-items">
          <div v-if="['code', 'name', 'label', 'address', 'decimals', 'symbol', 'chain', 'status'].includes(key)">{{
              key
            }}
          </div>
          <div v-if="['code', 'name', 'label', 'address', 'decimals', 'symbol', 'chain', 'status'].includes(key)">
            {{ value }}
          </div>
        </div>
      </div>
    </b-modal>
    <b-overlay
      :show="state.loading"
      rounded="sm"
    >
      <div class="demo-spacing-0 m-1 d-flex justify-content-between align-items-center"
           dir="rtl"
      >
        <h3 v-if="$route.name === 'show-accounts'">
          لیست زیر مجموعه ها
        </h3>
        <template v-else>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
            <feather-icon icon="FilterIcon"/>
            فیلتر
          </b-button>
        </template>
      </div>

      <search-and-filter
        :is-active.sync="isActive"
        :options="columns"
        @filter="getData(1, perPage)"
        @reset="getData(1, perPage)"
      />

      <b-modal
        ref="msg-modal"
        cancel-variant="outline-secondary"
        ok-title="ارسال پیام"
        cancel-title="لغو"
        centered
        title="پیام جدید"
        @ok.prevent="sendMessage"
        v-model="messageModal"
        size="lg"
      >
        <b-overlay :show="loading">
          <b-form>
            <b-form-group label="عنوان پیام">
              <b-form-input
                type="text"
                placeholder="عنوان"
                v-model="message.title"
              />
            </b-form-group>
            <b-form-group label="متن پیام">
              <b-form-textarea placeholder="متن پیام" v-model="message.body"/>
            </b-form-group>
          </b-form>
        </b-overlay>
      </b-modal>

      <div class="relative-over-x">
        <b-table
          ref="refUserListTable"
          :items="items"
          :small="true"
          responsive
          :fields="columns"
          primary-key="id"
          show-empty
          empty-text="اطلاعاتی یافت نشد"
          style="white-space: nowrap; min-height : 235px"
          @sort-changed="sort($event)"
          no-local-sort

        >
          <template #cell(name)="data">
                        <span dir="ltr">
                            {{ data.item.name }}
                        </span>
          </template>

          <template #cell(symbol)="data">
                        <span dir="ltr">
                            {{ data.item.symbol }}
                        </span>
          </template>

          <template #cell(status)="data">
            <b-badge
              pill
              :variant="'light-'+chainStatus(data.item.status)"
              class="text-capitalize"
            >
              {{ chainStatusLabel(data.item.status) }}
            </b-badge>
          </template>

          <template #cell(native_coin)="data">
            <b-button @click="triggerModalNativeCoin(data.item.native_coin)" v-if="data.item.native_coin">
              {{ data.item.native_coin ? data.item.native_coin.name : '' }}
            </b-button>
          </template>

          <template #cell(wallets)="data">
            <b-badge
              pill
              variant="light-info"
              class="text-capitalize"
            >
              {{ data.item.wallets.length }}
            </b-badge>
          </template>

          <template #cell(referrer_address)="{item}">
            <table-field-base :item="item"
                              key-name="referrer_address"
                              tooltip="اصلاح مقدار"
                              :address="'/chains/' + item.id"
                              method="patch"
            />
          </template>

          <template #cell(referrer_fee)="{item}">
            <table-field-base :item="item"
                              key-name="referrer_fee"
                              tooltip="اصلاح مقدار"
                              :address="'/chains/' + item.id"
                              method="patch"
            />
          </template>

          <template #cell(tokens)="data">
            <router-link :to="{
              name : 'Token',
               params: { chain:  data.item.symbol.toLowerCase() }
            }">
              مشاهده
            </router-link>

          </template>

          <template #cell(chains)="data">
            <router-link :to="{
              name : 'Wallet',
               params: { chain:  data.item.symbol.toLowerCase() }
            }">
              مشاهده
            </router-link>

          </template>


        </b-table>
      </div>

      <!-- pagination -->
      <div
        class="demo-spacing-0 d-flex justify-content-between m-1"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          align="left"
          @input="getData(currentPage,perPage)"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
        <div>
          <v-select
            id="perpage"
            v-model="perPage"
            dir="rtl"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
            style="min-width: 85px"
            @input="getData(1,perPage)"
          />
          <label for="perpage">تعداد در صفحه</label>
        </div>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BPagination,
  BCard,
  BButton,
  BTable,
  // BDropdown,
  // BDropdownItem,
  BBadge,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BOverlay
} from 'bootstrap-vue'
import SearchAndFilter from "@/layouts/components/SearchAndFilter";
import vSelect from 'vue-select';
// import NotAllowed from "@/layouts/components/NotAllowed";
import TableFieldBase from "@/Components/TableFieldBase";


export default {
  name: "Chain",
  components: {
    // NotAllowed,
    SearchAndFilter,
    BPagination,
    BCard,
    BButton,
    BTable,
    // BDropdown,
    // BDropdownItem,
    BBadge,
    vSelect,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BOverlay,
    TableFieldBase
  },
  data: () => ({
    test: 0,
    pageLength: 3,
    dir: false,
    searchTerm: '',
    currentPage: 1,
    perPage: 10,
    rows: 200,
    userData: [],
    isActive: false,
    messageModal: false,
    perPageOptions: [5, 10, 20, 50, 100],
    items: [],
    loading: false,
    loading2: false,
    message: {
      id: '',
      title: '',
      body: ''
    },
    columns: [
      {
        label: 'نام',
        key: 'name',
        sortable: false,
        searchType: 'text'
      },
      {
        label: 'symbol',
        key: 'symbol',
        sortable: false,
        searchType: 'text'
      },
      {
        label: 'وضعیت',
        key: 'status',
        sortable: false,
        searchType: 'select',
        selectOptions: [
          {value: 'ACTIVATED', label: 'فعال'},
          {value: 'DISABLED', label: 'غیرفعال'},
          {value: 'PENDING', label: 'در حال بررسی'},
          {value: 'DISABLED_BY_API', label: 'غیر فعال (provider)'},
        ]
      },
      {
        label: 'ارز پایه',
        key: 'native_coin',
        sortable: false,
        searchType: 'text'
      },
      {
        label: 'توکن ها',
        key: 'tokens',
        sortable: false,
        searchType: 'text'
      },
      {
        label: 'کارمزد',
        key: 'referrer_fee',
        sortable: false,
        searchType: 'text'
      },
      {
        label: 'آدرس',
        key: 'referrer_address',
        sortable: false,
        searchType: 'text'
      },
      {
        label: 'کیف پول ها',
        key: 'chains',
        sortable: false,
        searchType: 'text'
      },
      // {
      //   label: 'درخواست ها',
      //   key: 'orders'
      // },
      // {
      //   label: 'مدیریت',
      //   key: 'action'
      // },
    ],

    modalNativeCoin: false,
    modalNativeCoinInfo: {},
  }),
  computed: {
    chainStatus() {
      const a = {
        ACTIVATED: 'success',
        DISABLED: 'danger',
        PENDING: 'warning',
        DISABLED_BY_API: 'danger',
      }
      return e => a[e];
    },
    chainStatusLabel() {
      const a = {
        ACTIVATED: 'فعال',
        DISABLED: 'غیر فعال',
        PENDING: 'در حال بررسی ',
        DISABLED_BY_API: 'غیر فعال (پرووایدر)',
      }
      return e => a[e];
    },
    // georgianToJallali(){
    //     return e => this.$jmoment(e, 'YYYY-MM-DDTHH:mm:ss').format('dddd jD jMMMM jYYYY')
    // }
  },
  methods: {
    triggerModalNativeCoin(nativeCoin) {
      this.modalNativeCoin = true
      this.modalNativeCoinInfo = nativeCoin
    },

    activeOrdersPage(id) {
      this.push('withdraw-orders', id)
    },
    orderHistoryPage(id) {
      this.push('wallet-transactions', id)
    },
    push(to, id) {
      this.$router.push({name: to, query: {email: id}})
    },
    openMessageModal(e) {
      this.message.id = e
      this.messageModal = true
    },
    async sendMessage() {
      if (!this.loading) {
        this.loading = true
        try {
          await this.$axios.post('/users/' + this.message.id + '/notification', this.message)
          this.loading = false
          this.message = {
            id: '',
            title: '',
            body: '',
          }
          this.messageModal = false
          this.$error('پیام ارسال شد', '', 'success')
        } catch (e) {
          this.loading = false
        }

      }
    },

    changeStatus(e) {
      this.$swal({
        title: 'آیا از تغییر وضعیت کاربر مطمئن هستید؟',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'بله',
        cancelButtonText: 'خیر',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          console.warn('change active')
          this.state.loading = true
          const address = '/users/' + this.items[e].id + '/' + (this.items[e].is_banned ? 'un' : '') + 'ban'
          const res = await this.$axios.post(address)
          !res.data.message.includes('success')
          await this.getData(this.currentPage, this.perPage)

          this.$swal({
            icon: 'success',
            title: this.items[e].is_banned ? 'کاربر فعال شد' : 'کاربر غیر فعال شد',
            confirmButtonText: 'تایید',
            // text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },

    sort(e) {

      let sort = this.$toSnakeCase(e.sortBy)
      // let sort = e.sortBy
      let sorting = e.sortDesc ? 'DESC' : 'ASC'

      this.$router.push({
        query: {
          ...this.$route.query,
          orderBy: sort,
          sorting: sorting
        }
      })

      this.getData(1, this.perPage)

    },

    async getData(page, perPage) {
      this.state.loading = true

      let address = '/chains'
      let queryParams = {
        size: perPage,
        page: page,
        ...this.$route.query,
      }

      // if (!this.$route.query['orderBy']) {
      //   queryParams['orderBy'] = 'created_at'
      //   queryParams['orderByType'] = 'desc'
      // }

      if (this.$route.name === 'show-accounts') {
        address = '/users/' + this.$route.params.id + '/referring-users'
      }

      const res = await this.$axios(
        address,
        {
          params: queryParams
        }
      )
      this.state.loading = false
      this.items = res.data.data
      this.currentPage = res.data.meta.current_page
      this.rows = res.data.meta.total
    },

    async downloadExcel() {
      await this.$axios(
        {
          url: '/users',
          method: 'GET',
          responseType: 'blob', // important
          params: {
            export: 'excel',
            ...this.$route.query
          }
        }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.$jmoment().format(this.$dateFormat['faDateTime']) + '.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    }

  },
  mounted() {
    this.getData(1, this.perPage)
  },

}
</script>
<style lang="scss">
[v-cloak] {
  opacity: 0;
}

.nowrap {
  white-space: nowrap;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}

.native-coin-items {
  display: flex;
  justify-content: space-between;
}
</style>
